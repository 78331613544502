<template>
    <center>
        <div class="modal fade" id="costumModalDI" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataDI" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataDI" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <div class="modal fade" id="costumModalDICukup" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataDICukup" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataDICukup" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <div class="modal fade" id="costumModalDIKurang" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataDIKurang" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataDIKurang" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <div class="modal fade" id="costumModalDISangatKurang" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataDISangatKurang" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataDISangatKurang" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>


        <div class="modal fade" id="costumModalBendung" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataBendung" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 50px;">Kode Aset</th>
                                            <th style="width: 150px;">Nama Aset</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                            <th style="width: 50px;">Koordinat</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataBendung" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-justify">{{ datalist.NOMENKLATUR }}</td>
                                                <td class="text-justify">{{ datalist.NAMA_BANGUNAN }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                                <td class="text-justify">{{ datalist.LATITUDE }} , {{ datalist.LONGITUDE }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>

        <div class="modal fade" id="costumModalBendungCukup" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataBendungCukup" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 50px;">Kode Aset</th>
                                            <th style="width: 150px;">Nama Aset</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                            <th style="width: 50px;">Koordinat</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataBendungCukup" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-justify">{{ datalist.NOMENKLATUR }}</td>
                                                <td class="text-justify">{{ datalist.NAMA_BANGUNAN }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                                <td class="text-justify">{{ datalist.LATITUDE }} , {{ datalist.LONGITUDE }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>

        <div class="modal fade" id="costumModalBendungKurang" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataBendungKurang" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 50px;">Kode Aset</th>
                                            <th style="width: 150px;">Nama Aset</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                            <th style="width: 50px;">Koordinat</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataBendungKurang" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-justify">{{ datalist.NOMENKLATUR }}</td>
                                                <td class="text-justify">{{ datalist.NAMA_BANGUNAN }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                                <td class="text-justify">{{ datalist.LATITUDE }} , {{ datalist.LONGITUDE }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>

        <div class="modal fade" id="costumModalBendungSangatKurang" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Data</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid bg-breadcrumbberanda px-5">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikataBendungSangatKurang" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 50px;">Kode DI</th>
                                            <th style="width: 150px;">Nama DI</th>
                                            <th style="width: 50px;">Kode Aset</th>
                                            <th style="width: 150px;">Nama Aset</th>
                                            <th style="width: 100px;">Justifikasi</th>
                                            <th style="width: 50px;">Kebutuhan Air</th>
                                            <th style="width: 50px;">Debit Intake</th>
                                            <th style="width: 50px;">Faktor K</th>
                                            <th style="width: 50px;">Koordinat</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataDataBendungSangatKurang" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                <td class="text-justify">{{ datalist.KODE_DI }}</td>
                                                <td class="text-justify">{{ datalist.DAERAH_IRIGASI }}</td>
                                                <td class="text-justify">{{ datalist.NOMENKLATUR }}</td>
                                                <td class="text-justify">{{ datalist.NAMA_BANGUNAN }}</td>
                                                <td class="text-center">{{ datalist.JUSTIFIKASI }}</td>
                                                <td class="text-center">{{ datalist.KEBUTUHAN_AIR_IRIGASI }}</td>
                                                <td class="text-center">{{ datalist.DEBIT_INTAKE }}</td>
                                                <td class="text-center">{{ datalist.FAKTOR_K }}</td>
                                                <td class="text-justify">{{ datalist.LATITUDE }} , {{ datalist.LONGITUDE }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </center>
    <input type="hidden" id="linknya" v-model="linknya">
    <div id="myInfoLayer" class="sidenavkanan p-0">
        <div class="card card-warning shadow-none">
            <div class="card-header" style="background-color: #013970 !important;color: #ffffff;">
                <h3 class="card-title text-white">Info</h3>
                <!-- /.card-tools -->
                <div class="card-toolbar">
                    <button type="button" class="btn btn-warning float-right" @click="closeNavLayer()"><i
                            class="fa fa-times"></i></button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="color: black !important;min-height: 95vh;width: 90%; overflow: auto;"
                id="Info_Adit">

            </div>
        </div>
    </div>
    <div>
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading" style="opacity: 0.75;"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-0">
            <Top />
            <aside class="main-sidebar elevation-4 sidebar-dark-marron">
                <center>
                    <br>
                    <img src="../../../../src/assets/img/gis2.png" height="20">
                    <a class="nav-link wow fadeInDown" data-widget="pushmenu" href="#" role="button"
                        style="font-size: large;">
                        <i class="fas fa-angle-double-right text-white"></i>
                    </a>
                </center>

                <!-- Sidebar -->
                <div class="sidebar wow fadeInLeft">
                    <!-- Sidebar Menu -->
                    <nav class="mt-2">
                        <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-header">NERACA AIR</li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <i class="nav-icon fas fa-water"></i>
                                    <p>
                                        <span class="right badge badge-primary">Ketersediaan Air {{
                                            G_numFormatKoma(ketersediaan) }} <small> lt/dt</small></span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <i class="nav-icon fas fa-braille"></i>
                                    <p>
                                        <span class="right badge badge-secondary bg-purple">Kebutuhan Air {{
                                            G_numFormatKoma(kebutuhan) }} <small> lt/dt</small></span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <i class="nav-icon fab fa-xing-square"></i>
                                    <p>
                                        <span class="right badge badge-primary bg-orange">Faktor K {{
                                            G_numFormatKoma(faktork) }}
                                            <small> {{ justifikasi }}</small></span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-header">KONDISI AIR DAERAH IRIGASI</li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalDI()">
                                    <i class="nav-icon fas fa-bezier-curve"></i>
                                    <p>
                                        <span class="right badge badge-info">Jumlah Daerah Irigasi: {{
                                            G_numFormat(jumlahdi) }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalDICukup()">
                                    <i class="nav-icon fas fa-cloud-showers-heavy"></i>
                                    <p>
                                        <span class="right badge badge-success">Cukup Air: {{ G_numFormat(jumlahdicukup)
                                            }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalDIKurang()">
                                    <i class="nav-icon fas fa-cloud-sun-rain"></i>
                                    <p>
                                        <span class="right badge badge-warning">Kurang Air: {{
                                            G_numFormat(jumlahdikurang)
                                        }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalDISangatKurang()">
                                    <i class="nav-icon fas fa-cloud-sun"></i>
                                    <p>
                                        <span class="right badge badge-danger">Sangat Kurang Air: {{
                                            G_numFormat(jumlahdisangatkurang) }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-header">KONDISI AIR BENDUNG IRIGASI</li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalBendung()">
                                    <i class="nav-icon fab fa-delicious"></i>
                                    <p>
                                        <span class="right badge badge-info">Jumlah Bendung: {{
                                            G_numFormat(jumlahbendung) }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalBendungCukup()">
                                    <i class="nav-icon fa fa-tint"></i>
                                    <p>
                                        <span class="right badge badge-success">Cukup Air: {{ G_numFormat(cukup)
                                            }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalBendungKurang()">
                                    <i class="nav-icon fas fa-cloud-rain"></i>
                                    <p>
                                        <span class="right badge badge-warning">Kurang Air: {{ G_numFormat(kurang)
                                            }}</span>
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="bukamodalBendungSangatKurang()">
                                    <i class="nav-icon fas fa-cloud-moon-rain"></i>
                                    <p>
                                        <span class="right badge badge-danger">Sangat Kurang Air: {{
                                            G_numFormat(sangatkurang) }}</span>
                                    </p>
                                </a>
                            </li>
                            <li>&nbsp;</li>
                        </ul>
                    </nav>
                    <!-- /.sidebar-menu -->
                </div>
                <!-- /.sidebar -->
            </aside>
            <aside class="control-sidebar control-sidebar-dark"
                style="top: 0px; height: 100vh; width: 350px; display: none;background-color: #013970;">
                <!-- Control sidebar content goes here -->
                <div class="p-3 control-sidebar-content os-host os-theme-light os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition os-host-overflow os-host-overflow-y"
                    style="height: 683.2px;">
                    <div class="os-resize-observer-host observed">
                        <div class="os-resize-observer" style="left: 0px; right: auto;"></div>
                    </div>
                    <div class="os-size-auto-observer observed" style="height: calc(100% + 1px); float: left;">
                        <div class="os-resize-observer"></div>
                    </div>
                    <div class="os-content-glue" style="margin: -16px; width: 249px; height: 682px;"></div>
                    <div class="os-padding">
                        <div class="os-viewport os-viewport-native-scrollbars-invisible" style="overflow-y: scroll;">
                            <div class="card-header" style="background-color: #FFA500 !important;color: #111;">
                                <h3 class="card-title text-black" style="color: #111 !important;">Layer</h3>
                                <!-- /.card-tools -->
                                <div class="card-toolbar">
                                    <button type="button" class="btn btn-warning float-right"
                                        data-widget="control-sidebar" data-controlsidebar-slide="true" href="#"
                                        role="button"><i class="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div class="os-content" style="padding: 16px; height: 100%; width: 100%;">
                                <h5>Daftar Data Layer</h5>
                                <h6><b id="judultransparant">Transparansi Layer</b></h6>
                                <input id="opacity-input" type="range" min="0" max="1" step="0.01" value="0.75"
                                    style="width: 100%;" class="slider" /><span id="opacity-output"></span>
                                <hr class="mb-2">
                                <template v-for="(datalist, urutlist) in datapeta" :key="urutlist">
                                    <h6>{{ datalist.nama }}</h6>
                                    <template v-for="(datacek, urutcek) in datalist.children" :key="urutcek">
                                        <div class="mb-1">
                                            <input type="checkbox" :id="datacek.id" value="1" class="mr-1"
                                                @click="TampilLayer(datacek.id)">
                                            <span class="text-danger">
                                                {{ datacek.text }}
                                            </span>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
                        <div class="os-scrollbar-track">
                            <div class="os-scrollbar-handle" style="transform: translate(0px, 0px); width: 100%;"></div>
                        </div>
                    </div>
                    <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
                        <div class="os-scrollbar-track">
                            <div class="os-scrollbar-handle" style="transform: translate(0px, 0px); height: 52.8638%;">
                            </div>
                        </div>
                    </div>
                    <div class="os-scrollbar-corner"></div>
                </div>
            </aside>
            <div class="content" v-if="lebarlayar > 1024">&nbsp;</div>
            <div class="content" v-if="lebarlayar > 1024">&nbsp;</div>
            <div class="content" v-if="lebarlayar > 1024">&nbsp;</div>
            <div class="content" v-if="lebarlayar > 1024">&nbsp;</div>
            <div class="content" v-if="lebarlayar > 1024">&nbsp;</div>
            <div class="content">
                <div class="container-fluid">
                    <div class="card collapsed-card">
                        <div class="card-header">
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool text-white" data-card-widget="collapse">
                                    Filter Data <i class="fas fa-plus text-white"></i>
                                </button>
                            </div>
                            <a class="card-title" data-widget="pushmenu" href="#" role="button"
                                style="font-size: large;">
                                <i class="fas fa-angle-double-left text-white"></i> &nbsp;&nbsp;
                            </a>
                            <a class="card-title" data-widget="control-sidebar" data-controlsidebar-slide="true"
                                href="#" role="button" id="samping" @click="closeNavLayer()">
                                <i class="fas fa-th-large"></i> Layer Peta&nbsp;&nbsp;
                            </a>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <label>Data Tanggal</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                        </div>
                                        <input type="date" class="form-control form-control-sm" id="tanggal"
                                            name="tanggal" placeholder="Tanggal" v-model="tanggal"
                                            @change="ubahpeta();">
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>s/d Tanggal</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                        </div>
                                        <input type="date" class="form-control form-control-sm" id="sdtanggal"
                                            name="sdtanggal" placeholder="Tanggal" v-model="sdtanggal"
                                            @change="ubahpeta();">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label>Pilihan Kewenangan </label>
                                    <v-select :options="kewenangan" :reduce="(label) => label.code" label="label"
                                        v-model="defaultSelectedkewenangan" @update:modelValue="onkewenanganChange"
                                        style="background-color: #ffffff;"></v-select>
                                </div>
                                <div class="col-sm-6">
                                    <label>Pilihan Daerah Irigasi </label>
                                    <v-select :options="kodeDI" :reduce="(label) => label.code" label="label"
                                        v-model="defaultSelectedkodeDI" @update:modelValue="onkodeDIChange"
                                        style="background-color: #ffffff;"></v-select>
                                </div>
                                <div class="col-sm-6">
                                    <label>Pilihan Balai </label>
                                    <v-select :options="balai" :reduce="(label) => label.code" label="label"
                                        v-model="defaultSelectedbalai" @update:modelValue="onbalaiChange"
                                        style="background-color: #ffffff;"></v-select>
                                </div>

                            </div>
                        </div>
                        <!-- ./card-body -->
                    </div>
                    <iframe :src="'/map.html'" class="col-sm-12 text-center" style="min-height: 85vh;" frameborder="0"
                        id="maps" scrolling="no" @load="loadpeta()"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import vSelect from 'vue-select';
import Top from "./Template/Top.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, vSelect, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            tanggal: Date.now(),
            sdtanggal: Date.now(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            kewenangan: [],
            datakewenangan: [],
            defaultSelectedkewenangan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkewenangan: '',
            kodeDI: [],
            datakodeDI: [],
            defaultSelectedkodeDI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkodeDI: '',
            balai: [],
            databalai: [],
            defaultSelectedbalai: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedbalai: '',
            jumlahdi: 0,
            jumlahdicukup: 0,
            jumlahdikurang: 0,
            jumlahdisangatkurang: 0,
            jumlahbendung: 0,
            cukup: 0,
            kurang: 0,
            sangatkurang: 0,
            databendung: [],
            datacukup: [],
            datakurang: [],
            datasangatkurang: [],
            kebutuhan: 0,
            ketersediaan: 0,
            faktork: 0,
            justifikasi: '',
            datapeta: [],
            lebarlayar: window.innerWidth,
            daftarDI: [],
            carikataDI: '',
            daftarDICukup: [],
            carikataDICukup: '',
            daftarDIKurang: [],
            carikataDIKurang: '',
            daftarDISangatKurang: [],
            carikataDISangatKurang: '',

            daftarBendung: [],
            carikataBendung: '',
            daftarBendungCukup: [],
            carikataBendungCukup: '',
            daftarBendungKurang: [],
            carikataBendungKurang: '',
            daftarBendungSangatKurang: [],
            carikataBendungSangatKurang: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        }
    },
    computed: {
        filteredKataDataDI() {
            const filteredWorkers = this.carikataDI === ""
                ? this.daftarDI
                : this.daftarDI.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataDI.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataDI() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataDI === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataDI.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataDICukup() {
            const filteredWorkers = this.carikataDI === ""
                ? this.daftarDICukup
                : this.daftarDICukup.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataDICukup.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataDICukup() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataDICukup === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataDICukup.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataDIKurang() {
            const filteredWorkers = this.carikataDI === ""
                ? this.daftarDIKurang
                : this.daftarDIKurang.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataDIKurang.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataDIKurang() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataDIKurang === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataDIKurang.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataDISangatKurang() {
            const filteredWorkers = this.carikataDI === ""
                ? this.daftarDISangatKurang
                : this.daftarDISangatKurang.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataDISangatKurang.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataDISangatKurang() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataDISangatKurang === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataDISangatKurang.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataBendung() {
            const filteredWorkers = this.carikataBendung === ""
                ? this.daftarBendung
                : this.daftarBendung.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataBendung.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataBendung() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataBendung === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataBendung.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataBendungCukup() {
            const filteredWorkers = this.carikataBendungCukup === ""
                ? this.daftarBendungCukup
                : this.daftarBendungCukup.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataBendungCukup.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataBendungCukup() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataBendungCukup === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataBendungCukup.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataBendungKurang() {
            const filteredWorkers = this.carikataBendungKurang === ""
                ? this.daftarBendungKurang
                : this.daftarBendungKurang.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataBendungKurang.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataBendungKurang() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataBendungKurang === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataBendungKurang.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataBendungSangatKurang() {
            const filteredWorkers = this.carikataBendungSangatKurang === ""
                ? this.daftarBendungSangatKurang
                : this.daftarBendungSangatKurang.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikataBendungSangatKurang.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataBendungSangatKurang() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataBendungSangatKurang === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataBendungSangatKurang.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        loadpeta() {
            //this.halamanloading = true;
        },
        async ambildataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIReFF.get("parastapainnovation_p-GetKewenangan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.datakewenangan = Response.data.content.data;
                        this.datakewenangan.forEach((item) => {
                            this.kewenangan.push({
                                label: item.nama,
                                code: this.datakewenangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
        },
        onkewenanganChange(a) {
            try {
                this.selectedkewenangan = this.kewenangan[a].tag;
            }
            catch (err) {
                return;
            }
            this.defaultSelectedkodeDI = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedkodeDI = '';
            this.defaultSelectedbalai = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedbalai = '';
            this.filterdataReffPublik();
            this.ubahpeta();
        },
        onbalaiChange(a) {
            try {
                this.selectedbalai = this.balai[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
            this.ubahpeta();
        },
        onkodeDIChange(a) {
            try {
                this.selectedkodeDI = this.kodeDI[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
            this.ubahpeta();
        },
        async filterdataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            //alert(this.selectedkewenangan);
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })

        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationair-GetdataanalisisBendung?random=" + random + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan + "&tanggal=" + this.tanggal + "&sdtanggal=" + this.sdtanggal).then(
                Response => {
                    this.daftarBendung = [];
                    this.daftarBendungCukup = [];
                    this.daftarBendungKurang = [];
                    this.daftarBendungSangatKurang = [];
                    this.daftarDI = [];
                    this.daftarDICukup = [];
                    this.daftarDIKurang = [];
                    this.daftarDISangatKurang = [];
                    this.jumlahdi = 0;
                    this.jumlahdicukup = 0;
                    this.jumlahdikurang = 0;
                    this.jumlahdisangatkurang = 0;
                    this.jumlahbendung = 0;
                    this.cukup = 0;
                    this.kurang = 0;
                    this.sangatkurang = 0;
                    this.kebutuhan = 0;
                    this.ketersediaan = 0;
                    this.faktork = 0;
                    this.justifikasi = '';
                    this.databendung = [];
                    this.datacukup = [];
                    this.datakurang = [];
                    this.datasangatkurang = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.daftarDI = Response.data.content.detail[0].DI;
                        this.daftarDICukup = Response.data.content.detail[0].DI_CUKUP;
                        this.daftarDIKurang = Response.data.content.detail[0].DI_KURANG;
                        this.daftarDISangatKurang = Response.data.content.detail[0].DI_SANGATKURANG;
                        this.daftarBendung = Response.data.content.data;
                        this.daftarBendungCukup = Response.data.content.detail[0].CUKUP;
                        this.daftarBendungKurang = Response.data.content.detail[0].KURANG;
                        this.daftarBendungSangatKurang = Response.data.content.detail[0].SANGATKURANG;
                        this.jumlahdi = Response.data.resume[0].JUMLAHDI;
                        this.jumlahdicukup = Response.data.resume[0].JUMLAHDICUKUP;
                        this.jumlahdikurang = Response.data.resume[0].JUMLAHDIKURANG;
                        this.jumlahdisangatkurang = Response.data.resume[0].JUMLAHDISANGATKURANG;
                        this.jumlahbendung = Response.data.resume[0].JUMLAHBENDUNG;
                        this.cukup = Response.data.resume[0].CUKUP;
                        this.kurang = Response.data.resume[0].KURANG;
                        this.sangatkurang = Response.data.resume[0].SANGATKURANG;
                        this.kebutuhan = Response.data.resume[0].KEBUTUHAN;
                        this.ketersediaan = Response.data.resume[0].KETERSEDIAAN;
                        this.faktork = Response.data.resume[0].FAKTORK;
                        this.justifikasi = Response.data.resume[0].JUSTIFIKASI;
                        this.databendung = Response.data.content.data;
                        this.datacukup = Response.data.content.detail[0].CUKUP;
                        this.datakurang = Response.data.content.detail[0].KURANG;
                        this.datasangatkurang = Response.data.content.detail[0].SANGATKURANG;
                        //alert(temptabel.length);
                    }
                    else {
                        this.daftarBendung = [];
                        this.daftarBendungCukup = [];
                        this.daftarBendungKurang = [];
                        this.daftarBendungSangatKurang = [];
                        this.daftarDI = [];
                        this.daftarDICukup = [];
                        this.daftarDIKurang = [];
                        this.daftarDISangatKurang = [];
                        this.jumlahdi = 0;
                        this.jumlahdicukup = 0;
                        this.jumlahdikurang = 0;
                        this.jumlahdisangatkurang = 0;
                        this.jumlahbendung = 0;
                        this.cukup = 0;
                        this.kurang = 0;
                        this.sangatkurang = 0;
                        this.kebutuhan = 0;
                        this.ketersediaan = 0;
                        this.faktork = 0;
                        this.justifikasi = '';
                        this.databendung = [];
                        this.datacukup = [];
                        this.datakurang = [];
                        this.datasangatkurang = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.daftarBendung = [];
                this.daftarBendungCukup = [];
                this.daftarBendungKurang = [];
                this.daftarBendungSangatKurang = [];
                this.daftarDI = [];
                this.daftarDICukup = [];
                this.daftarDIKurang = [];
                this.daftarDISangatKurang = [];
                this.jumlahdi = 0;
                this.jumlahdicukup = 0;
                this.jumlahdikurang = 0;
                this.jumlahdisangatkurang = 0;
                this.jumlahbendung = 0;
                this.cukup = 0;
                this.kurang = 0;
                this.sangatkurang = 0;
                this.kebutuhan = 0;
                this.ketersediaan = 0;
                this.faktork = 0;
                this.justifikasi = '';
                this.databendung = [];
                this.datacukup = [];
                this.datakurang = [];
                this.datasangatkurang = [];
                this.halamanloading = false;
            });

            //this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovation-Getdatagis?random=" + random).then(
                Response => {
                    this.datapeta = [];
                    if (this.datapesan == 'data diketemukan') {
                        this.datapeta = Response.data.content.data;
                        //alert(JSON.stringify(this.datapeta));
                    }
                    else {
                        this.datapeta = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapeta = [];
                this.halamanloading = false;
            }); 
            this.halamanloading = false;
            this.ubahpeta();
        },
        ubahpeta() {
            this.TampilLayerAwal('layer|16|parastapainnovation-GetdataGISBendung?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20240925051020_56012dd6da6c7ca781a9198578889842.png|1|1|0|12|hatch|2');
        },
        TampilLayerAwal(id) {
            document.getElementById(id).checked = true;
            const message2 = this.linknya + '|false|' + id + '*layer' + '*' + this.selectedkewenangan + '*' + this.selectedkodeDI + '*' + this.selectedbalai + '*' + this.tanggal + '*' + this.sdtanggal;
            const iframe2 = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe2.contentWindow.postMessage(message2, "*");

            const message = this.linknya + '|true|' + id + '*layer' + '*' + this.selectedkewenangan + '*' + this.selectedkodeDI + '*' + this.selectedbalai + '*' + this.tanggal + '*' + this.sdtanggal;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        TampilLayer(id) {
            if (document.getElementById(id).checked == true) {
                const message = this.linknya + '|' + document.getElementById(id).checked + '|' + id + '*layer' + '*' + this.selectedkewenangan + '*' + this.selectedkodeDI + '*' + this.selectedbalai + '*' + this.tanggal + '*' + this.sdtanggal;
                const iframe = document.getElementById("maps");
                this.sourcenya = '';
                this.sourcenya = '/map.html';
                iframe.contentWindow.postMessage(message, "*");
            }
            else {
                const message = this.linknya + '|' + document.getElementById(id).checked + '|' + id + '*layer' + '*' + this.selectedkewenangan + '*' + this.selectedkodeDI + '*' + this.selectedbalai + '*' + this.tanggal + '*' + this.sdtanggal;
                const iframe = document.getElementById("maps");
                this.sourcenya = '';
                this.sourcenya = '/map.html';
                iframe.contentWindow.postMessage(message, "*");
            }
        },
        closeNavLayer() {
            document.getElementById("myInfoLayer").style.width = "0";
        },
        bukamodalDI() {
            $('#costumModalDI').modal('show');
        },
        bukamodalDICukup() {
            $('#costumModalDICukup').modal('show');
        },
        bukamodalDIKurang() {
            $('#costumModalDIKurang').modal('show');
        },
        bukamodalDISangatKurang() {
            $('#costumModalDISangatKurang').modal('show');
        },
        bukamodalBendung() {
            $('#costumModalBendung').modal('show');
        },
        bukamodalBendungCukup() {
            $('#costumModalBendungCukup').modal('show');
        },
        bukamodalBendungKurang() {
            $('#costumModalBendungKurang').modal('show');
        },
        bukamodalBendungSangatKurang() {
            $('#costumModalBendungSangatKurang').modal('show');
        },
    },
    mounted() {
        //this.getOtentifikasi();
        //this.ambildata();
        this.tanggal = this.formatDate(this.tanggal);
        this.sdtanggal = this.formatDate(this.sdtanggal);
        this.ambildataReffPublik();
        this.ambildata();
        document.getElementById('samping').click();
        document.getElementById('samping').click();
        //alert(this.lebarlayar);
    }
}
</script>
<style></style>